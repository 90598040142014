import React from "react";

import Bio from "./Bio";
import Intro from "../Intro";

import Andrea from "../../Styles/Assets/Bios/Andrea.jpg";
import Anika from "../../Styles/Assets/Bios/Anika.jpg";
import Cayden from "../../Styles/Assets/Bios/Cayden.jpg";
import Dan from "../../Styles/Assets/Bios/Dan.jpg";

import Esteban from "../../Styles/Assets/Bios/Esteban.jpg";
import Josh from "../../Styles/Assets/Bios/Josh.jpg";
import Rob from "../../Styles/Assets/Bios/Rob.jpg";
import Roger from "../../Styles/Assets/Bios/Roger.jpg";
import Jonathan from "../../Styles/Assets/Bios/Jonathan.jpg";
import Rusty from "../../Styles/Assets/Bios/Rusty.jpg";
import Olin from "../../Styles/Assets/Bios/Olin.jpeg";
import Mckye from "../../Styles/Assets/Bios/Mckye.jpg";
import Jaime from "../../Styles/Assets/Bios/Jaime.jpeg";
import Isaac from "../../Styles/Assets/Bios/Isaac.jpg";
import Mason from "../../Styles/Assets/Bios/Mason.jpg";
import Canyon from "../../Styles/Assets/Bios/Canyon.jpg";
import Kellie from "../../Styles/Assets/Bios/Kellie.jpg";
const About = () => {
  let bios = [
    {
      pic: Andrea,
      name: "Andrea Crawley",
      title: "President & Owner",
      bio: "Andrea is the one who keeps everything organized and running behind the scenes. She has years of management experience and is a skilled business strategist.",
    },
    {
      pic: Rob,
      name: "Robert Crawley",
      title: "Co-founder, Owner, VP of Finance",
      bio: "Robert has over 25 years of experience in financing, and has held many high-ranking positions such as CEO, CFO, and VP in his career. He has a Master's Degree in accounting from Brigham Young University. He specializes in management, human resources, finish carpentry, and roofing.",
      background: "#e3e3e3",
    },

    {
      pic: Dan,
      name: "Daniel Crawley",
      title: "Co-founder, owner",
      bio: "Daniel has over 13 years' experience as a realtor, and has been in sales for 20 years. With a Bachelor's degree in business management and a marketing degree from Utah Valley State College, he has owned, invested in, or sold nearly 200 flips. Specializes in flips, real estate, and project management.",
    },
    {
      pic: Josh,
      name: "Josh Smith",
      title: "Owner",
      bio: `Josh has spent many years as a general contractor. He specializes in flooring and banisters.`,
    },
    {
      pic: Esteban,
      name: "Esteban Sagrero",
      title: "Owner",
      bio: "Specializes in programming, storage, and marketing.",
      background: "#e3e3e3",
    },

    // {
    //   pic: Jonathan,
    //   name: "Jonathan Winston Crawley",
    //   title: "Owner",
    //   bio: "Specializes in tree removal, demolitions, and estate cleanups.",
    // },
    {
      pic: Anika,
      name: "Anika Moreno",
      title: "Accountant",
      bio: "Anika handles the company's finances and does all of the accounting work. She also helps the Office Manager keep everything organized—from inventory to current jobs and more.",
      background: "#e3e3e3",
    },
    {
      pic: Kellie,
      name: "Kellie Somerville",
      title: "Book Keeper",
      bio: "Kellie has been able to use her technical savviness and attention to detail to help grow within previous careers, most recently billing for a rapidly growing fiber-to-the-home network before joining Quality Carpentry. She prides herself on accuracy and timeliness. She graduated with a Bachelor's degree from Utah Valley University. Kellie and her husband reside in Utah with their 3 children. They enjoy hunting, fishing, and camping together.",
    },
    // {
    //   pic: Camie,
    //   name: "Camie Wagner",
    //   title: "Payroll Specialist",
    //   bio: "Camie is a student at Utah State University studying Business Management.  She hopes to become a consultant and help businesses. Camie looks forward to learning new skills and working to grow her knowledge on all the ins and outs of payroll!",
    //   background: "#e3e3e3",
    // },
  

    {
      pic: Roger,
      name: "Roger Stubbs",
      title: "Project Manager",
      bio: "Roger holds a General Contractor license and has 10 years of experience in flips, remodels, framing, and plumbing. He loves training and helping his employees be the best they can be.",
    },
    // {
    //   pic: Rusty,
    //   name: "Rusty Stubbs",
    //   title: "Project Manager",
    //   bio: "Rusty is a handyman who loves carpentry, plumbing, electrical work, and anything else he can get his hands on.",
    // },

    // {
    //   pic: Cayden,
    //   name: "Cayden Dennison",
    //   title: "Employee",
    //   bio: "His name is Cayden, but most people call him Denny. He loves learning new things and getting to know new people.",
    // },
    

    // {
    //   pic: Mckye,
    //   name: "Mckye Miller",
    //   title: "Employee",
    //   bio: "Mckye has finish carpentry and landscaping experience. He loves to learn new skills and try new things. ",
    // },
    // {
    //   pic: Jaime,
    //   name: "Jaime De Robles",
    //   title: "Project Manager",
    //   bio: "Jaime De Robles specializes in handyman work, carpentry, construction, and project management. He has 7+ years of consistent personal growth work and communication training with a passion for business and real estate. Jaime is also a Certified    Transformational Life Coach. ",
    // },
    {
      pic: Olin,
      name: "Olin Vickers",
      title: "Project Manager",
      bio: "Olin is the Roofing Specialist at Quality Carpentry. He has 14 years of experience in the construction industry with over a decade of that being involved with roofing.",
    },{
      pic: Canyon,
      name: "Canyon Miller",
      title: "Employee",
      bio: "Canyon has experience with home restoration and likes to try new things. He is also eager to learn new things.   ",
    },

    // {
    //   pic: Mason,
    //   name: "Mason Rankin",
    //   title: "Employee",
    //   bio: "Mason is a well rounded individual who has a passion for hard work. He loves working with his hands building projects for his wife and seeing their creations come to life.",
    // },
  ];

  return (
    <div className="about">
      <Intro
        primaryText="MEET YOUR NEW TEAM"
        secondaryText="We'll get the job done together"
      />
      <div className="bio_grid">
        {bios.map(({ pic, name, title, bio }, index) => {
          return (
            <Bio key={index} pic={pic} name={name} title={title} bio={bio} />
          );
        })}
      </div>
    </div>
  );
};

export default About;
